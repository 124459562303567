import image from '@/assets/icons/image.svg'
import video from '@/assets/icons/video.svg'
import file from '@/assets/icons/file.svg'

export default function getFileIcon (extension) {
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return image

    case 'mp4':
    case 'avi':
    case 'mkv':
    case 'mov':
    case 'flv':
    case 'wmv':
    case 'mpg':
    case 'mpeg':
    case 'm4v':
    case '3gp':
    case '3g2':
    case 'm2ts':
    case 'mts':
    case 'ts':
    case 'mxf':
    case 'webm':
      return video

    default:
      return file
  }
}
