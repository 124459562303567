<template>
  <section class="resource-table">
    <table class="resource-table-inner">
      <thead>
      <tr class="table-header">
        <th
          v-for="column in tableCols"
          :key="column.key"
          :style="{width: column.width}"
        >
          <button class="table-column-text" @click="emit('changeSort', column.key)">
            <span>{{ column.name }}</span>
            <img
              v-if="column.key === sortField"
              :src="arrowTop"
              style="margin-left: 10px;"
              :style="{transform: sortDirection === 'desc' ? 'rotate(180deg)' : ''}"
              alt="arrow"
            />
          </button>
        </th>
      </tr>
      </thead>
      <tbody>
      <transition-group name="flip-list">
        <tr
          v-for="resource in resources"
          :key="resource.id.key"
          class="table-row"
        >
          <td class="filename-td">
            <label :for="resource.fileName">
              <img :src="getFileIconHandler(resource.fileName)" alt="file icon" style="margin-right: 10px;" />
              <span>{{ resource.fileName }}</span>
            </label>
          </td>
          <td>{{ formatTime(resource.lastModified) }}</td>
          <td>{{ getFileSizeString(resource.fileSize) }}</td>
          <td>
            <VDropdown>
              <button class="more-btn">
                <img :src="ellipsis" alt="more">
              </button>
              <template #popper>
                <a class="dropdown-container" @click="emit('download', resource.id)">
                  <img :src="download" alt="download">
                  <span>Download</span>
                </a>
              </template>
            </VDropdown>
          </td>
        </tr>
      </transition-group>
      </tbody>
    </table>
  </section>

</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import moment from 'moment'
import arrowTop from '@/assets/icons/arrow-top.svg'
import ellipsis from '@/assets/icons/ellipsis.svg'
import download from '@/assets/icons/download.svg'
import getFileSizeString from '@/utils/getFileSizeString'
import getFileIcon from '@/utils/getFileIcon'

const tableCols = [
  {
    name: 'File name',
    key: 'fileName',
    width: '45%'
  },
  {
    name: 'Last modified',
    key: 'lastModified',
    width: '25%'
  },
  {
    name: 'File size',
    key: 'fileSize',
    width: '10%'
  },
  {
    name: '',
    key: 'download',
    width: ''
  }
]

const props = defineProps({
  resources: {
    type: Array,
    required: true
  },
  sortField: {
    type: [String, null],
    required: true
  },
  sortDirection: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  selectFile: null,
  download: null
})

const getFileIconHandler = (filename) => {
  const extension = filename.split('.').pop()
  return getFileIcon(extension)
}

const formatTime = (time) => {
  return moment(time).format('DD/MM/YYYY hh:mm a')
}
</script>

<style scoped>
.resource-table {
  width: 100%;
  padding: 25px;
  border-radius: 20px;
  background: white;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  font-size: 16px;
}

.resource-table-inner {
  width: 100%;
}

table {
  border-collapse: collapse;
}

th {
  padding: 15px 0;
  text-align: left;
}

.table-header th:first-child {
  padding-left: 15px;
}

.table-column-text {
  border: 0;
  padding: 0;
  background: none;
  font-family: 'Helvetica Neue Lt Std Bold', Arial, sans-serif;
  font-size: 14px;
  color: #c9ccce;
  cursor: pointer;
}

.table-row td {
  min-height: 60px;
  padding: 20px 0;
  border-bottom: 1px solid #f4f6f5;
  transition: all 0.3s ease-in-out;
}

.table-row:hover {
  background: #f4f6f5;
}

.table-row td:first-child {
  padding-left: 15px;
}

.table-row td:last-child {
  text-align: right;
  padding-right: 15px;
}

.table-row:last-child td {
  border-bottom: 0;
}

.filename-td {
  display: flex;
  align-items: center;
}

.more-btn {
  background: none;
  border: 0;
  cursor: pointer;
}

.dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  text-decoration: none;
  color: #5f6562;
  cursor: pointer;
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
<style>
.v-popper--theme-dropdown .v-popper__inner {
  padding: 30px;
  border: 0;
  border-radius: 20px;
  background: #f4f6f5;
  box-shadow: 2px 3px 19px rgba(0, 0, 0, 0.21);
}

.v-popper--theme-dropdown .v-popper__arrow-container {
  display: none;
}
</style>
