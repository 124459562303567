<template>
  <table class="resource-table">
    <thead>
    <tr class="table-header">
      <th
        v-for="column in tableCols"
        :key="column.key"
        :style="{width: column.width}"
      >
        <button class="table-column-text" @click="emit('changeSort', column.key)">
          <span>{{ column.name }}</span>
          <img
            v-if="column.key === sortField"
            :src="arrowTop"
            style="margin-left: 10px;"
            :style="{transform: sortDirection === 'desc' ? 'rotate(180deg)' : ''}"
            alt="arrow"
          />
        </button>
      </th>
    </tr>
    </thead>
    <tbody>
      <tr class="table-body-container">
        <transition-group name="flip-list">
          <td
            v-for="resource in resources"
            :key="resource.id.key"
            class="table-item"
          >
            <img :src="getImageSrc(resource)" alt="preview" class="table-item-preview">
            <div class="table-item-info">
              <span style="color: #5f6562">{{ resource.fileName }}</span>
              <span>{{ formatTime(resource.lastModified) }}</span>
              <span>{{ getFileSizeString(resource.fileSize) }}</span>
            </div>
          </td>
        </transition-group>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import arrowTop from '@/assets/icons/arrow-top.svg'
import getFileIcon from '@/utils/getFileIcon'
import getFileSizeString from '@/utils/getFileSizeString'
import moment from 'moment'

const tableCols = [
  {
    name: 'File name',
    key: 'name',
    width: '45%'
  }
]

const props = defineProps({
  resources: {
    type: Array,
    required: true
  },
  sortField: {
    type: [String, null],
    required: true
  },
  sortDirection: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  changeSort: null
})

const getImageSrc = (resource) => {
  const extension = resource.fileName.split('.').pop()
  return getFileIcon(extension)
}

const formatTime = (time) => {
  return moment(time).format('DD/MM/YYYY hh:mm a')
}
</script>

<style scoped>
.resource-table {
  width: 100%;
  padding: 25px;
  border-radius: 20px;
  background: white;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  font-size: 16px;
}

th {
  padding: 15px 0;
  text-align: left;
}

.table-header th {
  border-bottom: 1px solid #f4f6f5;
}

.table-header th:first-child {
  padding-left: 15px;
}

.table-column-text {
  border: 0;
  padding: 0;
  background: none;
  font-family: 'Helvetica Neue Lt Std Bold', Arial, sans-serif;
  font-size: 14px;
  color: #c9ccce;
  cursor: pointer;
}

.table-body-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.table-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  row-gap: 20px;
}

.table-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 10px;
  font-size: 14px;
  color: #c9ccce;
}

.table-item-preview {
  width: 120px;
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
