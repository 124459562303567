<template>
  <section>
    <div class="divider" />
    <div class="header-container">
      <h3 class="header-3">Resource library</h3>
      <div class="display-view-container">
        <span style="margin-right: 10px;">View as:</span>
        <button
          v-for="view in tableViews"
          :key="view.name"
          v-html="view.icon"
          class="display-item"
          :class="{'active-display-item': view.name === selectedView}"
          @click="selectedView = view.name"
         />
      </div>
    </div>
    <ListResourceTable
      v-if="selectedView === 'list' && resources"
      :resources="sortedResources"
      :sort-field="sortField"
      :sort-direction="sortDirection"
      @change-sort="changeSort"
      @download="downloadFile"
    />
    <GridResourceTable
      v-if="selectedView === 'grid' && resources"
      :resources="sortedResources"
      :sort-field="sortField"
      :sort-direction="sortDirection"
      @change-sort="changeSort"
      @download="downloadFile"
    />
  </section>
</template>

<script setup>
import { ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import tableViews from '@/data/tableViews'
import ListResourceTable from '@/components/ListResourceTable'
import GridResourceTable from '@/components/GridResourceTable'
import ResourceAPI from '@/api/ResourceAPI'
import roles from '@/data/roles'

const store = useStore()

const resources = ref([])

const selectedView = ref(tableViews[0].name)
const sortField = ref(null)
const sortDirection = ref('asc')
const sortedResources = ref(resources)

const changeSort = (key) => {
  if (sortField.value === key) {
    if (sortDirection.value === 'desc') {
      sortField.value = null
    } else {
      sortDirection.value = 'desc'
    }
  } else {
    sortField.value = key
    sortDirection.value = 'asc'
  }
}

const downloadFile = async (id) => {
  // is it safe?
  const a = document.createElement('a')
  a.href = `https://s3.amazonaws.com/${id.bucket}/${id.key}`
  a.click()
}

watchEffect(async () => {
  if (!store.state.user.accessType) return
  try {
    resources.value = await ResourceAPI.getMyResources()
  } catch (error) {
    console.error(error)
  }
})

watch([sortField, sortDirection], () => {
  if (sortField.value) {
    sortedResources.value = [...resources.value].sort((a, b) => {
      if (sortDirection.value === 'asc') {
        return a[sortField.value] > b[sortField.value] ? 1 : -1
      } else {
        return a[sortField.value] < b[sortField.value] ? 1 : -1
      }
    })
  } else {
    sortedResources.value = resources.value
  }
})

watch([selectedView], () => {
  sortField.value = null
  sortDirection.value = 'asc'
  sortedResources.value = resources.value
})
</script>

<style scoped>
.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 40px;
  border: 0;
  background: rgba(201, 204, 206, 0.38);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 25px;
}

.header-3 {
  font-family: 'Helvetica Neue Lt Std', Arial, sans-serif;
  font-size: 18px;
  color: #FF8217;
  text-transform: uppercase;
}

.display-view-container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.display-item {
  width: 35px;
  height: 35px;
  padding: 0;
  border: 0;
  background: none;
  fill: #c9ccce;
  cursor: pointer;
  transition: fill 0.2s ease-in-out;
}

.active-display-item {
  fill: #FF8217;
}
</style>
