import grid from '@/assets/icons/grid'
import list from '@/assets/icons/list'

export default [
  {
    name: 'grid',
    icon: grid
  },
  {
    name: 'list',
    icon: list
  }
]
