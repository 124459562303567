import { resourceClient } from '@/api/clients'

const endpoints = {
  upload: '/resources/upload',
  getMetadata: (bucket, key) => `/resources/${bucket}/${key}`,
  deleteResource: (bucket, key) => `/resources/${bucket}/${key}`,
  download: (bucket, key) => `/resources/download/${bucket}/${key}`,
  assignPassedRoles: (bucket, key) => `/resources/${bucket}/${key}/roles`,
  deleteAccess: (bucket, key) => `/resources/${bucket}/${key}/roles`,
  getMyResources: '/roles/me/resources',
  getResources: role => `roles/${role}/resources`
}

export default class ResourceAPI {
  static async upload (file) {
    const response = await resourceClient.post(endpoints.upload, file)
    return response.data
  }

  static async getMetadata (bucket, key) {
    const response = await resourceClient.get(endpoints.getMetadata(bucket, key))
    return response.data
  }

  static async deleteResource (bucket, key) {
    const response = await resourceClient.delete(endpoints.deleteResource(bucket, key))
    return response.data
  }

  static async download (id) {
    const response = await resourceClient.get(endpoints.download(id.bucket, id.key))
    return response.data
  }

  static async assignPassedRoles (bucket, key, roles) {
    const response = await resourceClient.post(endpoints.assignPassedRoles(bucket, key), roles)
    return response.data
  }

  static async deleteAccess (bucket, key, roles) {
    const response = await resourceClient.delete(endpoints.deleteAccess(bucket, key), roles)
    return response.data
  }

  static async getMyResources () {
    const response = await resourceClient.get(endpoints.getMyResources)
    return response.data
  }

  static async getResources (role) {
    const response = await resourceClient.get(endpoints.getResources(role))
    return response.data
  }
}
